import QueueItem from "./components/QueueItem";
import CaseSummary from "./components/CaseSummary";
import Branding from "./plugins/Branding";
import MainLogo from "./plugins/MainLogo";
import {Icons} from "@stacc/flow-ui-components";
import {CaseDetailsTranslated} from "./components/EvryCaseDetails";
import FailedTask from "./components/FailedTask";

const CustomComponents = {
    case: {
        caseSummary: {component: CaseSummary},
        caseDetails: CaseDetailsTranslated,
        tasks: {
            taskLists: [
                {
                    id: "pending-tasks",
                    title: "Pending tasks",
                    query: "status=pending",
                },
                {
                    id: "failed-tasks",
                    title: "Failed tasks",
                    query: "status=failed",
                    failedTask: FailedTask
                },
            ],
        },
    },
    queue: {
        queueItem: QueueItem,
    },
    queueDefinition: {
        "all-cases-queue": {
            name: "all-cases-queue",
            queueFilter: "?flowDefinitionId=company-customer-declaration",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "active-cases-queue": {
            name: "active-cases-queue",
            queueFilter:
                "?status=!completed&status=!archived&flowDefinitionId=company-customer-declaration",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "completed-cases-queue": {
            name: "completed-cases-queue",
            queueFilter:
                "?status=completed&data.process.status=!archived&flowDefinitionId=company-customer-declaration",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "cancelled-cases-queue": {
            name: "cancelled-cases-queue",
            queueFilter:
                "?data.process.status=archived&flowDefinitionId=company-customer-declaration",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "failed-cases-queue": {
            name: "failed-cases-queue",
            queueFilter:
                "?hasIncidents=true&flowDefinitionId=company-customer-declaration&status=!archived",
            queueSort: "sort=_id&dir=-1",
            limit: 10,
        },
        "corporate-periodic-renewal-all-cases-queue": {
            name: "corporate-periodic-renewal-all-cases-queue",
            queueFilter: "?flowDefinitionId=corporate-periodic-renewal",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "corporate-periodic-renewal-active-cases-queue": {
            name: "corporate-periodic-renewal-active-cases-queue",
            queueFilter:
                "?status=!completed&status=!archived&flowDefinitionId=corporate-periodic-renewal",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "corporate-periodic-renewal-completed-cases-queue": {
            name: "corporate-periodic-renewal-completed-cases-queue",
            queueFilter:
                "?status=completed&flowDefinitionId=corporate-periodic-renewal",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "corporate-periodic-renewal-cancelled-cases-queue": {
            name: "corporate-periodic-renewal-cancelled-cases-queue",
            queueFilter:
                "?status=archived&flowDefinitionId=corporate-periodic-renewal",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "corporate-periodic-renewal-failed-queue": {
            name: "corporate-periodic-renewal-failed-queue",
            queueFilter:
                "?status=!archived&&hasIncidents=true&flowDefinitionId=corporate-periodic-renewal",
            queueSort: "sort=_id&dir=-1",
            limit: 10,
        },
        "corporate-periodic-renewal2-all-cases-queue": {
            name: "corporate-periodic-renewal2-all-cases-queue",
            queueFilter: "?flowDefinitionId=corporate-periodic-renewal2",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "corporate-periodic-renewal2-active-cases-queue": {
            name: "corporate-periodic-renewal2-active-cases-queue",
            queueFilter:
                "?status=!completed&status=!archived&flowDefinitionId=corporate-periodic-renewal2",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "corporate-periodic-renewal2-completed-cases-queue": {
            name: "corporate-periodic-renewal2-completed-cases-queue",
            queueFilter:
                "?status=completed&flowDefinitionId=corporate-periodic-renewal2",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "corporate-periodic-renewal2-cancelled-cases-queue": {
            name: "corporate-periodic-renewal2-cancelled-cases-queue",
            queueFilter:
                "?status=archived&flowDefinitionId=corporate-periodic-renewal2",
            queueSort: "sort=_id&dir=-1",
            limit: 30,
        },
        "corporate-periodic-renewal2-failed-queue": {
            name: "corporate-periodic-renewal2-failed-queue",
            queueFilter:
                "?status=!archived&hasIncidents=true&flowDefinitionId=corporate-periodic-renewal2",
            queueSort: "sort=_id&dir=-1",
            limit: 10,
        },
        "cleanup-files-and-flows-queue": {
            name: "cleanup-files-and-flows-queue",
            queueFilter: "?&flowDefinitionId=cleanup-files-and-flows",
            queueSort: "sort=_id&dir=-1",
            limit: 10,
        },
    },
    menu: {
        menuStructure: [
            {
                name: "company-customer-declaration-form-queues",
                elems: [
                    "all-cases-queue",
                    "active-cases-queue",
                    "completed-cases-queue",
                    "cancelled-cases-queue",
                    "failed-cases-queue",
                ],
                isDefaultOpen: true,
                icon: Icons.Table,
            },
            {
                name: "corporate-periodic-renewal-queues",
                elems: [
                    "corporate-periodic-renewal-all-cases-queue",
                    "corporate-periodic-renewal-active-cases-queue",
                    "corporate-periodic-renewal-completed-cases-queue",
                    "corporate-periodic-renewal-cancelled-cases-queue",
                    "corporate-periodic-renewal-failed-queue",
                ],
                isDefaultOpen: true,
                icon: Icons.Phone,
            },
            {
                name: "corporate-periodic-renewal2-queues",
                elems: [
                    "corporate-periodic-renewal2-all-cases-queue",
                    "corporate-periodic-renewal2-active-cases-queue",
                    "corporate-periodic-renewal2-completed-cases-queue",
                    "corporate-periodic-renewal2-cancelled-cases-queue",
                    "corporate-periodic-renewal2-failed-queue",
                ],
                isDefaultOpen: true,
                icon: Icons.Phone,
            },
            {
                name: "cleanup-files-and-flows-queue",
                icon: Icons.Remove,
            },
        ],
    },
};

const StartableProcesses = [
    "company-customer-declaration",
    "cleanup-files-and-flows",
    "corporate-periodic-renewal",
    "corporate-periodic-renewal2",
];
const SearchFields = [
    "data.bank.orgId",
    "data.company.organizationNumber.value",
    "data.companyOrgNumber",
    "data.customer.customerInformation.name",
    "data.company.name.value",
];

export {
    Branding,
    MainLogo,
    CustomComponents,
    SearchFields,
    StartableProcesses,
};
